


























import { Component, Vue } from 'vue-property-decorator';
import { ContratApporteursAffaires } from '@/models';
import ValidationAction from '@/components/ValidationAction.vue';
import ContratApporteursAffairesInfos from './ContratApporteursAffairesInfos.vue';
import ContratApporteursAffairesOwner from './ContratApporteursAffairesOwner.vue';
import { Action, Getter } from 'vuex-class';
import { ContratApporteursAffairesStoreMethods } from '@/store/modules/contratApporteursAffaires/contratApporteursAffairesStore';
import { Profils } from '@/shared/enums';
import { ConvertHelper } from '@/shared/helpers';

/**
 * Composant de gestion d'un contrat apporteur d'affaires.
 */
@Component({
    name: 'ContratApporteursAffairesForm',
    components: { ContratApporteursAffairesOwner, ContratApporteursAffairesInfos, ValidationAction },
})
export default class ContratApporteursAffairesForm extends Vue {
    public $refs!: {
        form: HTMLFormElement;
    };

    @Getter(ContratApporteursAffairesStoreMethods.CONTRAT_APPORTEURS_AFFAIRES)
    public contratApporteursAffaires: ContratApporteursAffaires;

    // Permet de faire en sorte que le template ait accès à l'enum.
    public Profils = Profils;

    /*
     * Méthodes du store.
     */
    @Action(ContratApporteursAffairesStoreMethods.CREER_CONTRAT_APPORTEURS_AFFAIRES)
    public creerContratApporteursAffaires: () => Promise<ContratApporteursAffaires>;

    @Action(ContratApporteursAffairesStoreMethods.MODIFIER_CONTRAT_APPORTEURS_AFFAIRES)
    public modififierContratApporteursAffaires: () => Promise<ContratApporteursAffaires>;

    private loading = false;

    /**
     * Récupère le titre de la page en fonction de s'il s'agit d'une création ou d'une modification.
     */
    public get titrePage(): string {
        return (this.contratApporteursAffaires.id > 0 ? 'Informations du' : 'Création d\'un') + ' contrat apporteurs d\'affaires';
    }

    /**
     * Envoi du formulaire
     */
    public submit(): void {
        if (this.$refs.form.validate()) {
            this.loading = true;

            // On détermine si c'est une création ou une modification.
            const actionSauvegarder = this.contratApporteursAffaires.id > 0
                ? this.modififierContratApporteursAffaires
                : this.creerContratApporteursAffaires;

            // On autorise la saisie au format "0,0", il faut donc pouvoir reconvertir les chaines avec les virgules en flottants.
            this.contratApporteursAffaires.partBeneficiaireClassique = ConvertHelper.parseFloat(this.contratApporteursAffaires.partBeneficiaireClassique.toString());
            this.contratApporteursAffaires.partBeneficiairePrecarite = ConvertHelper.parseFloat(this.contratApporteursAffaires.partBeneficiairePrecarite.toString());
            this.contratApporteursAffaires.partApporteurAffaires = ConvertHelper.parseFloat(this.contratApporteursAffaires.partApporteurAffaires.toString());

            // Sauvegarde.
            actionSauvegarder().then((result: any) => {
                if (result) {
                    (this.$router as any).push({ name: 'contrats-apporteurs-affaires' });
                } 
            });
        }
    }

    /**
     * Retour à la page d'aministration.
     */
    public annulationEdition() {
        (this.$router as any).push({ name: 'contrats-apporteurs-affaires' });
    }

}
