import { Component, Mixins } from 'vue-property-decorator';
import { ValidationMixin } from '@/shared/mixins/ValidationMixin';

@Component({})
export class ContratApporteursaffairesInfosValidator extends Mixins(ValidationMixin) {
    public nomRules = [
        (v: string | any) => this.required(v) || 'Le libellé du contrat est obligatoire' ,
    ];
    public responsableRules = [
        (v: number | any) => v > 0 || 'Le responsable du contrat est obligatoire',
    ];
    public dateDebutRules = [
        (v: string | any) => this.required(v) || 'La date de début de validité est obligatoire' ,
        (v: string | any) => this.isDateValide(v) || 'La date de début de validité n\'est pas valide' ,
    ];
    public dateFintRules = [
        (v: string | any) => this.required(v) || 'La date de fin de validité est obligatoire' ,
        (v: string | any) => this.isDateValide(v) || 'La date de fin de validité n\'est pas valide' ,
    ];
    public volumeClassiqueRules = [
        (v: number | any) => this.required(v) || 'Le volume classique est obligatoire' ,
        (v: number | any) => this.isNumeric(v) || 'Le volume classique n\'est pas valide' ,
    ];

    public valorisationClassiqueRules = [
        (v: number | any) => this.required(v) || 'La valorisation classique est obligatoire' ,
        (v: number | any) => this.isNumeric(v, true) || 'La valorisation classique n\'est pas valide' ,
    ];

    public volumePrecaRules = [
        (v: number | any) => this.required(v) || 'Le volume précarité est obligatoire' ,
        (v: number | any) => this.isNumeric(v) || 'Le volume précarité n\'est pas valide' ,
    ];

    public valorisationPrecaRules = [
        (v: number | any) => this.required(v) || 'La valorisation précarité est obligatoire' ,
        (v: number | any) => this.isNumeric(v, true) || 'La valorisation précarité n\'est pas valide' ,
    ];
    public remunerationAARules = [
        (v: number | any) => this.required(v) || 'La rémunération de l\'apporteur d\'affaires est obligatoire' ,
        (v: number | any) => this.isNumeric(v, true) || 'La rémunération de l\'apporteur d\'affaires n\'est pas valide' ,
    ];
}