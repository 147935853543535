





















































































import { Component, Mixins } from 'vue-property-decorator';
import CustomCard from '@/components/CustomCard.vue';
import CeeDatePicker from '@/components/CeeDatePicker.vue';
import { Getter, Mutation } from 'vuex-class';
import { ContratApporteursAffaires } from '@/models';
import { ContratApporteursAffairesStoreMethods } from '@/store/modules/contratApporteursAffaires/contratApporteursAffairesStore';
import { ContratApporteursaffairesInfosValidator } from './validator/ContratApporteursAffairesInfos.Validator'
import { DateHelper } from '@/shared/helpers';

@Component({
    name: 'ContratApporteursAffairesInfos',
    components: { CustomCard, CeeDatePicker }
})
export default class ContratApporteursAffairesInfos extends Mixins(ContratApporteursaffairesInfosValidator) {
    @Getter(ContratApporteursAffairesStoreMethods.CONTRAT_APPORTEURS_AFFAIRES)
    public contratApporteursAffaires: ContratApporteursAffaires;

    @Mutation(ContratApporteursAffairesStoreMethods.SET_CONTRAT_APPORTEURS_AFFAIRES_BY_PROPERTIES)
    public setPropertyContratAA: (ac: object) => void;

    public get prixFixeAuContrat(): boolean {
        return this.contratApporteursAffaires.prixFixeAuContrat;
    }
    public set prixFixeAuContrat(prixFixeAuContrat: boolean) {
        this.setPropertyContratAA({ prixFixeAuContrat: !!prixFixeAuContrat });
    }

    public get fluxFinancier(): boolean {
        return this.contratApporteursAffaires.fluxFinancier;
    }
    public set fluxFinancier(fluxFinancier: boolean) {
        this.setPropertyContratAA({ fluxFinancier: !!fluxFinancier });
    }
    public get nom(): string {
        return this.contratApporteursAffaires.nom;
    }
    public set nom(nom: string) {
        this.setPropertyContratAA({ nom: nom });
    }
    public get volumeClassique(): number {
        return this.contratApporteursAffaires.volumeClassique;
    }
    public set volumeClassique(volumeClassique: number) {
        this.setPropertyContratAA({ volumeClassique: volumeClassique });
    }
    public get volumePrecarite(): number {
        return this.contratApporteursAffaires.volumePrecarite;
    }
    public set volumePrecarite(volumePrecarite: number) {
        this.setPropertyContratAA({ volumePrecarite: volumePrecarite });
    }
    public get partBeneficiaireClassique(): number {
        return this.contratApporteursAffaires.partBeneficiaireClassique;
    }
    public set partBeneficiaireClassique(partBeneficiaireClassique: number) {
        this.setPropertyContratAA({ partBeneficiaireClassique: partBeneficiaireClassique });
    }
    public get partBeneficiairePrecarite(): number {
        return this.contratApporteursAffaires.partBeneficiairePrecarite;
    }
    public set partBeneficiairePrecarite(partBeneficiairePrecarite: number) {
        this.setPropertyContratAA({ partBeneficiairePrecarite: partBeneficiairePrecarite });
    }
    public get partApporteurAffaires(): number {
        return this.contratApporteursAffaires.partApporteurAffaires;
    }
    public set partApporteurAffaires(partApporteurAffaires: number) {
        this.setPropertyContratAA({ partApporteurAffaires: partApporteurAffaires });
    }

    /// Permet de savoir si la date de début est antérieure à la date de fin.
    public get dateRule(): (() => boolean | string) {
        return () => ( new Date(DateHelper.toIsoString(this.contratApporteursAffaires.dateDebut)) < new Date(DateHelper.toIsoString(this.contratApporteursAffaires.dateFin)) || 'La date de fin est antérieure à la date de début.');
    }

    /**
     * Réinitialisation des parts de l'AA.
     */
    private resetParts(): void {
        if (this.contratApporteursAffaires.prixFixeAuContrat) {
            this.contratApporteursAffaires.partBeneficiaireClassique = 0;
            this.contratApporteursAffaires.partBeneficiairePrecarite = 0;
            this.contratApporteursAffaires.partApporteurAffaires = 0;
        }
    }
}
