


























import { Component, Mixins } from 'vue-property-decorator';
import CustomCard from '@/components/CustomCard.vue';
import CeeAutocomplete from '@/components/CeeAutocomplete.vue';
import { Getter, Mutation, Action } from 'vuex-class';
import { ContratApporteursAffairesStoreMethods } from '@/store/modules/contratApporteursAffaires/contratApporteursAffairesStore';
import { ContratApporteursAffaires, Utilisateur } from '@/models';
import { ContratApporteursaffairesOwnerValidator } from './validator/ContratApporteursAffairesOwner.Validator';

@Component({
    name: 'ContratApporteursAffairesOwner',
    components: { CustomCard, CeeAutocomplete }
})
export default class ContratApporteursAffairesOwner extends Mixins(ContratApporteursaffairesOwnerValidator) {

    @Getter(ContratApporteursAffairesStoreMethods.CONTRAT_APPORTEURS_AFFAIRES)
    public contratApporteursAffaires: ContratApporteursAffaires;

    @Getter(ContratApporteursAffairesStoreMethods.APPORTEUR_AFFAIRES_SELECTIONNE)
    private apporteurAffaires: { id: number, prenomNom: string };

    @Mutation(ContratApporteursAffairesStoreMethods.SET_CONTRAT_APPORTEURS_AFFAIRES_BY_PROPERTIES)
    public setPropertyContratAA: (property: object) => void;

    @Action(ContratApporteursAffairesStoreMethods.RECUPERER_APPORTEURS_AFFAIRES_AUTOCOMPLETE)
    public getApporteursAffairesAutoComplete: (recherche: string) => Promise<Utilisateur[]>;

    /**
    * Récupère le texte d'un membre utilisateur pour la sélection.
    * @param utilisateur L'utilisateur.
    */
    public getTexteUtilisateur(utilisateur: Utilisateur): string {
        return utilisateur.prenomNom + this.getSocietesUtilisateur(utilisateur);
    }

    /**
     * Récupère le texte associé aux sociétés d'un membre utilisateur.
     * @param utilisateur L'utilisateur.
     */
    public getSocietesUtilisateur(utilisateur: Utilisateur): string {
        return (utilisateur.societes && utilisateur.societes.length > 0)
            ? ` - ${utilisateur.societes.map(s => s.raisonSociale + ' (' + s.siret + ')').join(' / ')}`
            : '';
    }
}
