import { Component, Vue } from 'vue-property-decorator';
import template from './CreerModifierContratApporteursAffaires.Template.vue';
import ContratApporteursAffairesForm from '@/components/ContratApporteursAffaires/ContratApporteursAffairesForm.vue';
import {ContratApporteursAffaires } from '@/models';
import { ContratApporteursAffairesStoreMethods } from '@/store/modules/contratApporteursAffaires/contratApporteursAffairesStore';
import { Action, Mutation } from 'vuex-class';

@Component({
    ...template,
    name: 'CreerModifierApporteursAffaires',
    components: {ContratApporteursAffairesForm},
})
export default class CreerModifierApporteursAffaires extends Vue {

    @Action(ContratApporteursAffairesStoreMethods.RECUPERER_CONTRAT_APPORTEUR_AFFAIRES)
    public getContratApporteurAffaires: (payload: { id: number }) => Promise<ContratApporteursAffaires>;

    @Mutation(ContratApporteursAffairesStoreMethods.RESET_ALL)
    public resetAll: () => void;

    public created() {
        this.resetAll();
    }
    public mounted() {
        if (!!this.$route.params.id) {
            this.getContratApporteurAffaires({id: parseInt(this.$route.params.id, 10)});
        }
    }
}
