



























import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({
    name: 'Customcard',
})
export default class Customcard extends Vue {
    @Prop({ default: 'General Title Card' })
    private title!: string;
    @Prop({ default: 'General SubTitle Card' })
    private subTitle!: string;

    @Prop({ default: false })
    private showArrow!: boolean;

    private show = true;
}
